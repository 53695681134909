@use '../utilities/index.scss' as *;

/**
 * Tables
 */

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 1rem;
  border: none;

  tr {
    &:first-child {
      td,
      th {
        border-top: none;
      }
    }

    &:last-child {
      td,
      th {
        border-bottom: none;
      }
    }
  }

  td,
  th {
    border: 1px solid $color-gray-85;
    border-left: 0;
    border-right: 0;
    padding: 1rem;
    vertical-align: top;
    text-align: center;
  }

  th {
    @include font-accent();
    text-transform: uppercase;

    &[scope = 'row'] {
      text-align: left;
    }
  }
}
