@use 'sass:map';

/**
 * Aspect Ratios
 *
 * Ratios are listed width-height
 */

/* Variables */
$aspect-ratio-1-1: 1;
$aspect-ratio-2-1: 2;
$aspect-ratio-16-9: 1.7777;
$aspect-ratio-3-2: 1.5;
$aspect-ratio-4-3: 1.3333;
$aspect-ratio-7-5: 1.4;
$aspect-ratio-golden: 1.61803398875;

/* Map */
$aspect-ratios: (
  '1-1': $aspect-ratio-1-1,
  '2-1': $aspect-ratio-2-1,
  '16-9': $aspect-ratio-16-9,
  '3-2': $aspect-ratio-3-2,
  '4-3': $aspect-ratio-4-3,
  '7-5': $aspect-ratio-7-5,
  'golden': $aspect-ratio-golden
);

/* Gets the percentage difference of the ratio */
@function aspect-ratio-get-percentage ($ratio) {
  @if map.has-key($aspect-ratios, $ratio) {
    @return calc(1 / map.get($aspect-ratios, $ratio)) * 100%;
  }

  @else {
    @warn '`#{$ratio}` is not defined in the `$aspect-ratios` map.';
    @return 1;
  }
}

/* Applies padding to a box to create a given aspect ratio */
/* This will not accommodate overflow content and usually the child elements */
/* are absolutely-positioned or nonexistent */
@mixin aspect-ratio-box ($ratio) {
  @if map.has-key($aspect-ratios, $ratio) {
    // stylelint-disable-next-line function-no-unknown
    padding-bottom: aspect-ratio-get-percentage($ratio);
  }

  @else {
    @warn '`#{$ratio}` is not defined in the `$aspect-ratios` map.';
  }
}

/* Creates a flexible box of a given aspect ratio which can accommodate child */
/* elements of various types, and will allow the box to expand if the child */
/* exceeds the height of the box. */
@mixin aspect-ratio-box-flex ($ratio) {
  @if map.has-key($aspect-ratios, $ratio) {
    &::before {
      content: '';
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      // stylelint-disable-next-line function-no-unknown
      padding-top: aspect-ratio-get-percentage($ratio);
    }

    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }

  @else {
    @warn '`#{$ratio}` is not defined in the `$aspect-ratios` map.';
  }
}
