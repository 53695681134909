@use 'sass:color';
/* stylelint-disable color-no-hex, max-line-length, function-no-unknown */
/**
 * Colors
 *
 * ADA compliance ratings listed are for AA Normal Text.
 * https://webaim.org/resources/contrastchecker/
 *
 * Garmin Web Color Style Guide
 * https://www.figma.com/file/0ZMWi1CqvNp9qyY7sBzCRg/SOURCE-OF-TRUTH---Web-Design-System?node-id=631%3A340
 *
 */

/* -- Base Colors -- */
// Do not change these values
$color-transparent: transparent;
$color-white: #fff; // lightness: 100%
$color-black: #000; // lightness: 0%
$color-gray: #eee; // lightness: 93.33%
$color-garmin-blue: #106fad; // lightness: 37.06%
$color-candy-blue: #6dcff6; // lightness: 69.61%
$color-green: #4bb450; // lightness: 50%
$color-yellow: #ffaf33; // lightness: 60%
$color-red: #920000; // lightness: 28.63%

/* -- Kicker Colors -- */
// Do not use these colors outside of WWW + Category Pages Featured Cards Kicker Colors
$color-kicker-red: #c20f2f;
$color-kicker-pink: #f7607b;
$color-kicker-gold: #f0b800;
$color-kicker-green: #cad54f;

/* -- Gray -- */
$color-gray-95: color.change($color-gray, $lightness: 95%);
$color-gray-90: color.change($color-gray, $lightness: 90%);
$color-gray-85: color.change($color-gray, $lightness: 85%);
$color-gray-80: color.change($color-gray, $lightness: 80%);
$color-gray-70: color.change($color-gray, $lightness: 70%);
$color-gray-60: color.change($color-gray, $lightness: 60%);
$color-gray-50: color.change($color-gray, $lightness: 50%);
// ↑ ADA Compliant on Black ↑
// ↓ ADA Compliant on White ↓
$color-gray-45: color.change($color-gray, $lightness: 45%);
$color-gray-40: color.change($color-gray, $lightness: 40%);
$color-gray-30: color.change($color-gray, $lightness: 30%);
$color-gray-20: color.change($color-gray, $lightness: 20%);
$color-gray-15: color.change($color-gray, $lightness: 15%);
$color-gray-10: color.change($color-gray, $lightness: 10%);
$color-gray-05: color.change($color-gray, $lightness: 5%);

$color-gray-standard: $color-gray-45;
// Use when gray is used on text, e.g. disabled links. ADA compliant on white.
$color-gray-standard-accent: $color-gray-85;
// Use when gray is an accent, e.g. border

/* -- Garmin Blue -- */
$color-garmin-blue-90: color.change($color-garmin-blue, $lightness: 90%);
$color-garmin-blue-80: color.change($color-garmin-blue, $lightness: 80%);
$color-garmin-blue-70: color.change($color-garmin-blue, $lightness: 70%);
$color-garmin-blue-60: color.change($color-garmin-blue, $lightness: 60%);
$color-garmin-blue-50: color.change($color-garmin-blue, $lightness: 50%);
// ↑ ADA Compliant on Black ↑
// ↓ ADA Compliant on White ↓
$color-garmin-blue-40: $color-garmin-blue;
$color-garmin-blue-30: color.change($color-garmin-blue, $lightness: 30%);
$color-garmin-blue-20: color.change($color-garmin-blue, $lightness: 20%);
$color-garmin-blue-10: color.change($color-garmin-blue, $lightness: 10%);

$color-light-garmin-blue: $color-garmin-blue-60;
$color-dark-garmin-blue: $color-garmin-blue-20;

/* -- Candy Blue -- */
$color-candy-blue-90: color.change($color-candy-blue, $lightness: 90%);
$color-candy-blue-80: color.change($color-candy-blue, $lightness: 80%);
$color-candy-blue-70: $color-candy-blue;
$color-candy-blue-60: color.change($color-candy-blue, $lightness: 60%);
$color-candy-blue-50: color.change($color-candy-blue, $lightness: 50%);
$color-candy-blue-40: color.change($color-candy-blue, $lightness: 40%);
// ↑ ADA Compliant on Black ↑
// ↓ ADA Compliant on White ↓
$color-candy-blue-30: color.change($color-candy-blue, $lightness: 30%);
$color-candy-blue-20: color.change($color-candy-blue, $lightness: 20%);
$color-candy-blue-10: color.change($color-candy-blue, $lightness: 10%);

$color-light-candy-blue: $color-candy-blue-80;
$color-dark-candy-blue: $color-candy-blue-30;

/* -- Green -- */
$color-green-90: color.change($color-green, $lightness: 90%);
$color-green-80: color.change($color-green, $lightness: 80%);
$color-green-70: color.change($color-green, $lightness: 70%);
$color-green-60: color.change($color-green, $lightness: 60%);
$color-green-50: $color-green;
$color-green-40: color.change($color-green, $lightness: 40%);
// ↑ ADA Compliant on Black ↑
// ↓ ADA Compliant on White ↓
$color-green-30: color.change($color-green, $lightness: 30%);
$color-green-20: color.change($color-green, $lightness: 20%);
$color-green-10: color.change($color-green, $lightness: 10%);

$color-light-green: $color-green-80;
$color-dark-green: $color-green-30;

/* -- Yellow -- */
$color-yellow-90: color.change($color-yellow, $lightness: 90%);
$color-yellow-80: color.change($color-yellow, $lightness: 80%);
$color-yellow-70: color.change($color-yellow, $lightness: 70%);
$color-yellow-60: $color-yellow;
$color-yellow-50: color.change($color-yellow, $lightness: 50%);
$color-yellow-40: color.change($color-yellow, $lightness: 40%);
// ↑ ADA Compliant on Black ↑
// ↓ ADA Compliant on White ↓
$color-yellow-30: color.change($color-yellow, $lightness: 30%);
$color-yellow-20: color.change($color-yellow, $lightness: 20%);
$color-yellow-10: color.change($color-yellow, $lightness: 10%);

$color-light-yellow: $color-yellow-80;
$color-dark-yellow: $color-yellow-30;

/* -- Red -- */
$color-red-90: color.change($color-red, $lightness: 90%);
$color-red-80: color.change($color-red, $lightness: 80%);
$color-red-70: color.change($color-red, $lightness: 70%);
$color-red-60: color.change($color-red, $lightness: 60%);
$color-red-50: color.change($color-red, $lightness: 50%);
// ↑ ADA Compliant on Black ↑
// ↓ ADA Compliant on White ↓
$color-red-40: color.change($color-red, $lightness: 40%);
$color-red-30: $color-red;
$color-red-20: color.change($color-red, $lightness: 20%);
$color-red-10: color.change($color-red, $lightness: 10%);

$color-light-red: $color-red-80;
$color-dark-red: $color-red-20;

/* -- Abstracted Colors -- */
// Use these where possible to allow apps to consume colors semantically

$color-primary: $color-black;
// Primary color besides white
$color-primary-text: $color-white;
// Text color above the primary color
$color-secondary: $color-garmin-blue;
$color-success: $color-green;
$color-caution: $color-yellow;
$color-error: $color-red;
