@use 'breakpoints.scss' as *;

/**
 * Typography
 *
 * Prefix variables here with font- for scope.
 */

// Font stacks
$font-fallback-sans-serif: 'Prompt', sans-serif;
$font-roboto: 'Roboto';
$font-roboto-condensed: 'Roboto Condensed';
$font-noto: 'Noto Sans TC', 'Noto Sans SC', 'Noto Sans JP', 'Noto Sans KR';
$font-oswald: 'Oswald';
$font-superscripts-roboto: 'Superscripts-Roboto';
$font-superscripts-insidesup-roboto: 'Superscripts-InsideSup-Roboto';
$font-superscripts-oswald: 'Superscripts-Oswald';
$font-superscripts-insidesup-oswald: 'Superscripts-InsideSup-Oswald';

// Font Sizes
$font-size-root: 16px;
$font-size-large: 1.125rem;
$font-size-small: 0.875rem;
$font-size-tiny: 0.6875rem; // Legal

@mixin font-size ($size: root) {
  @if $size == small {
    font-size: $font-size-small;
  }
  @else if $size == tiny {
    font-size: $font-size-tiny;
  }
  @else if $size == root {
    font-size: $font-size-root;
  }
  @else if $size == large {
    font-size: $font-size-large;
  }
}

// Root line height
$font-line-height: 1.5;

/**
 * Abstracted typography variables and placeholders
 *
 * Use these except where your situation requires something specific. This
 * allows our underlying design to be more nimble.
 */

// Primary

/**
  * All APAC locales load the Roboto font-files via WCS Global Head
  * component
  *
  * The zh-TW locale additionally loads "Noto Sans TC" font file
  * The zh-CN locale additionally loads "Noto Sans SC" font file
  * The ja-JP locale additionally loads "Noto Sans JP" font file
  * The ko-KR locale additionally loads "Noto Sans KR" font file
  *
  * With this, even though multiple Noto Sans font families are defined in
  * the $font-noto variable, only the one Noto San font file variant that is
  * actually loaded into the HTML file will be used in the font-stack
  *
  * Similarly, the th-TH locale additionally loads the "Prompt" font file,
  * which is used in $font-fallback-sans-serif
  */
@mixin font-primary () {
  // stylelint-disable-next-line max-line-length
  font-family: $font-superscripts-roboto, $font-roboto, $font-noto, $font-fallback-sans-serif;
}

%font-primary {
  @include font-primary();
}

@mixin font-primary-sup () {
  // stylelint-disable-next-line max-line-length
  font-family: $font-superscripts-insidesup-roboto, $font-roboto, $font-noto, $font-fallback-sans-serif;
}

%font-primary-sup {
  @include font-primary-sup();
}

@mixin font-primary-weight-regular () {
  font-weight: 400;
}

%font-primary-weight-regular {
  @include font-primary-weight-regular();
}

@mixin font-primary-weight-medium () {
  font-weight: 500;
}

%font-primary-weight-medium {
  @include font-primary-weight-medium();
}

@mixin font-primary-weight-bold () {
  font-weight: 700;
}

%font-primary-weight-bold {
  @include font-primary-weight-bold();
}

// Accent

/**
  * All APAC locales load the Roboto font-files via WCS Global Head
  * component, except for vi-VN.
  *
  * The zh-TW locale additionally loads "Noto Sans TC" font file
  * The zh-CN locale additionally loads "Noto Sans SC" font file
  * The ja-JP locale additionally loads "Noto Sans JP" font file
  * The ko-KR locale additionally loads "Noto Sans KR" font file
  *
  * With this, even though multiple Noto Sans font families are defined in
  * the $font-noto variable, only the one Noto San font file variant that is
  * actually loaded into the HTML file will be used in the font-stack
  *
  * Similarly, the th-TH locale additionally loads the "Prompt" font file,
  * which is used in $font-fallback-sans-serif
  * The vi-VN locale instead loads Roboto Condensed.
  */
@mixin font-accent () {
  // stylelint-disable-next-line max-line-length
  font-family: $font-superscripts-oswald, $font-oswald, $font-roboto-condensed, $font-roboto, $font-noto, $font-fallback-sans-serif;
  font-weight: 400; // There is a single weight for this font.
  letter-spacing: 0.05rem;
}

@mixin font-accent-sup () {
  // stylelint-disable-next-line max-line-length
  font-family: $font-superscripts-insidesup-oswald, $font-oswald, $font-roboto-condensed, $font-roboto, $font-noto, $font-fallback-sans-serif;
}

%font-accent {
  @include font-accent();
}

%font-accent-sup {
  @include font-accent-sup();
}

// Headings

@mixin heading ($level: 1) {
  @include font-accent();
  line-height: 1.25;

  @if $level == 1 {
    font-size: 2rem;
    margin: 1rem 0;

    @include breakpoint(standard) {
      font-size: 2.5rem;
    }
  }
  @else if $level == 2 {
    font-size: 1.5rem;
    margin: 1rem 0;

    @include breakpoint(standard) {
      font-size: 2rem;
    }
  }
  @else if $level == 3 {
    font-size: 1.25rem;
    margin: 1rem 0;

    @include breakpoint(standard) {
      font-size: 1.5rem;
    }
  }
  @else if $level == 4 {
    font-size: 1rem;
    margin: 1rem 0;

    @include breakpoint(standard) {
      font-size: 1.25rem;
    }
  }
  @else if $level == 5 {
    font-size: 0.875rem;
    margin: 1rem 0;

    @include breakpoint(standard) {
      font-size: 1rem;
    }
  }
  @else if $level == 6 {
    font-size: 0.875rem;
    margin: 1rem 0;

    @include breakpoint(standard) {
      font-size: 1rem;
    }
  }
}

%heading {
  @extend %font-accent;
  margin: 1rem 0;
}

%heading-1 {
  @include heading(1);
}

%heading-2 {
  @include heading(2);
}

%heading-3 {
  @include heading(3);
}

%heading-4 {
  @include heading(4);
}

%heading-5 {
  @include heading(5);
}

%heading-6 {
  @include heading(6);
}
