@use '@garmin/insignia/sass/index' as insignia;

/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-max-id */
#teconsent {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding-bottom: 0.5rem;
  padding-top: 0;

  @include insignia.breakpoint(lg) {
    text-align: right;
    padding-right: 1.5rem;
    margin-top: -1rem;
    padding-bottom: 1.5rem;
  }
}

#teconsent a {
  color: #fff !important;
  text-decoration: none;
  font-family: Roboto, 'Noto Sans TC', 'Noto Sans SC', 'Noto Sans JP', 'Noto Sans KR', Prompt, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

#consent-blackbar {
  position: fixed;
  z-index: 110;
  width: 100%;
  background-color: #000;
  margin-top: 0;
  margin-bottom: 0;
}

#truste-consent-track {
  display: flex;
  justify-content: center;
}

#truste-consent-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  width: 100%;

  @include insignia.breakpoint(lg) {
    flex-flow: row wrap;
  }
}

#truste-consent-text {
  margin: 1rem;
  color: #fff !important;
}

#truste-consent-buttons {
  display: flex;
  margin: 0.5rem;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @include insignia.breakpoint(lg) {
    flex-direction: row;
  }
}

#truste-consent-button,
#truste-consent-required {
  color: #000 !important;
  background-color: #fff !important;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.5rem;
  text-transform: uppercase;
  border: 1px solid #fff !important;
  text-align: center;

  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
    background-color: #000 !important;
  }
}

#truste-show-consent {
  color: #fff !important;
  background-color: #000 !important;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.5rem;
}

/* stylelint-disable-next-line selector-id-pattern */
#consent_blackbar {
  position: fixed;
  z-index: 100000;
  width: 100%;
  bottom: 0;
  background-color: #000;
  border-top: solid 1px #fff;
  margin: 0 auto;
}

#top {
  background-color: #000 !important;
}
/* stylelint-enable declaration-no-important */
/* stylelint-enable selector-max-id */
