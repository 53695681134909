/*
  Garmin Web Component Library Global Overrides
  Import this in your project's main styles.scss file
*/

@use '@garmin/insignia/sass/utilities' as insignia;

/* stylelint-disable selector-pseudo-element-no-unknown, selector-class-pattern */

.g__vertical-nav-container {
  // Allows the mobile layout to expand
  height: 100%;
}

.g__vertical-nav-body {
  // Removes the baked-in padding and allows the mobile layout to expand
  height: 100%;
  padding: unset;
}

.g__vertical-nav-heading {
  // Adds padding to the mobile header since the styleObject does not work
  padding: 0 1rem;
}

.g__button {
  // Prevents the button from wrapping each letter onto a different line like they do by default
  word-break: normal;
}

.g__tooltip {
  // Removes the baked-in margin and sets the font size to match the label font size
  margin: 0;
  font-size: 0.9rem;
}

.g__card {
  // These styles remove the baked-in margin on cards and fix the offset issues caused by using a box shadow as a border
  max-width: calc(100% - 2px);
  margin: 1px;
  padding: 1rem;
}

.g__card .g__button--contained {
  margin: 0;
}

.g__input .g__input__wrapper,
.g__dropdown select,
.g__button__host:not(.g__card .g__flex > .g__button__host),
g-pagination li,
g-pagination li > .g__button__host--small,
.g__help-text li {
  // Removes the baked-in bottom margin for several components
  margin-bottom: 0;
}

.g__help-text ol {
  list-style-type: decimal;
  margin-left: 1em;
}

.g-icon {
  // These styles make icons respect the size defined on their host component
  display: block;
  width: 100%;
  height: 100%;
}

.g__loader {
  // Sets a default size (that can be overridden)
  width: 10rem;
}

/* Utility overrides for .g__ classes */
.g-button-full-width .g__button {
  width: 100%;
}

.g-button-auto-width .g__button {
  width: auto;
}

@media (max-width: insignia.$breakpoint-sm) {
  .g__button__host {
    // Prevent g-button from having 100% width on small screens
    width: auto;
  }
}

/* stylelint-enable selector-pseudo-element-no-unknown, selector-class-pattern */
