/* You can add global styles to this file, and also import other style files */
@use 'stylesheets/reset';
@use 'stylesheets/teconsent';
@use '@garmin/insignia/sass/index' as insignia;
@use '@garmin-avcloud/avcloud-fly-web-common/shared/styles/g-overrides';
@use '@garmin-avcloud/avc-maps-display/styles';
@use '@garmin-avcloud/avcloud-fly-web-common/shared/styles/link-styled-button';

a,
button {
  cursor: pointer;
}

.responsive-container {
  @include insignia.responsive-container(0, 1rem, insignia.$breakpoint-lg);
}

.no-wrap-text {
  white-space: nowrap;
}
