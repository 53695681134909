@use '../utilities/index.scss' as *;

/**
 * Links
 */

a {
  text-decoration: underline;
  color: $color-primary;
  transition: color 0.2s;

  &:active,
  &:focus {
    color: $color-gray-20;
  }

  &:hover {
    color: $color-gray-20;
  }
}
