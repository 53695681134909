@use '../utilities/index.scss' as *;

/**
 * Code
 */

code {
  font-family: monospace, serif;
  background: $color-gray-95;
  border: 1px solid $color-gray-standard-accent;
  padding: 0.25em;
  border-radius: 0.25em;
  @include font-size(small);
}
