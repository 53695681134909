@use '../utilities/index.scss' as *;

/**
 * Base styles for a page
 */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  @extend %font-primary;
  @extend %font-primary-weight-regular;
  font-size: $font-size-root;
  line-height: $font-line-height;
  margin: 0;

  @supports (font: -apple-system-body) {
    font: -apple-system-body, sans-serif;
  }
}
