@use '../utilities/index.scss' as *;

/**
 * Superscript
 */

sup {
  @extend %font-primary-sup;
  font-size: max(52%, 0.4em);
  line-height: 1;
  position: relative;
  top: 0;
  vertical-align: super;
}

h1 sup,
h2 sup,
h3 sup,
h4 sup,
h5 sup,
h6 sup {
  @extend %font-accent-sup;
  font-size: max(41%, 0.4em);
}

h1 sup {
  top: -0.35rem;
}

h2 sup {
  top: -0.275rem;
}

h3 sup {
  top: -0.2rem;
}

h4 sup {
  top: -0.15rem;
}

h5 sup {
  top: -0.1rem;
}
