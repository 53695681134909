/**
 * Unicode character codes
 * These are frequently used within CSS content properties
 */

$character-space: '\0020';
$character-space-nonbreaking: '\00a0';
$character-ampersand: '\0026'; // &
$character-curly-quote-left: '\2018'; // ‘
$character-curly-quote-right: '\2019'; // ’
$character-double-curly-quote-left: '\201C'; // “
$character-double-curly-quote-right: '\201D'; // ”
$character-middot: '\00b7'; // ·
$character-bullet: '\2022'; // •
$character-ellipsis: '\2026'; // …
$character-ellipsis-mid: '\22EF'; // ⋯
$character-em-dash: '\2014'; // —
$character-en-dash: '\2013'; // –
$character-hyphen: '\2010'; // -
$character-greater-than: '\003e'; // >
$character-less-than: '\003c'; // <
$character-checkmark: '\2713'; // ✓
