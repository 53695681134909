@use 'breakpoints.scss' as *;

/**
 * Display-related placeholders, mixins, and utilities.
 */

/**
 * An ADA-compliant means of visually hiding content without hiding the content
 * from screenreaders.
 */

@mixin visually-hidden () {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

%visually-hidden {
  @include visually-hidden();
}

// meant to unset the visually-hidden
@mixin visually-shown () {
  position: relative;
  overflow: visible;
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
}

/**
 * Clearfix
 *
 * Prevents pesky issues with floats and their alignment.
 */

@mixin clearfix () {
  &::after {
    clear: both;
  }

  &::after,
  &::before {
    display: table;
    content: ' ';
  }
}

%clearfix {
  @include clearfix();
}

/**
 * Vertically center
 */

@mixin vertical-center ($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

/**
 * Unstyled list
 */

@mixin list-unstyled (
  $no-margin: true,
  $no-padding: true
) {
  list-style-type: none;

  @if $no-margin {
    margin: 0;
  }

  @if $no-padding {
    padding: 0;
  }
}

/**
 * Inline list elements
 */
@mixin list-inline (
  $space: false, // space to be applied between elements
  $no-margin: true
) {

  @include list-unstyled($no-margin);

  > li {
    display: inline-block;
  }

  @if $space {
    > li + li {
      margin-left: $space;
    }
  }
}

/**
 * Responsive Container
 *
 * An easy way to make a responsive container element.
 */

/* stylelint-disable-next-line max-line-length */
@mixin responsive-container ($margin: 1rem, $padding: 1rem, $max-width: $breakpoint-max-width) {
  margin: $margin;
  padding: $padding;

  @include breakpoint(standard) {
    max-width: $max-width;
    margin: $margin auto;
  }
}
