/**
 * Lists
 */

ol,
ul {
  li {
    margin-bottom: 1rem;
  }
}
